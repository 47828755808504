import { asText, stripRichTextWrapperTag } from '../../utils/html';
import { cardsAdapter } from '../edito-carousel/adapter';
import { routerLinks, marketLinks } from '../../utils/fetch-links';
import dataI18n from '~/i18n/messages/fr-fr.related-guides.json';
import mergeI18n from '~/utils/merge-i18n';

const querySingleGuide = async (id, prismic) =>
  prismic.client.getByID(id, {
    fetchLinks: [...routerLinks, ...marketLinks],
  });

const queryGuides = async (
  id,
  numberOfGuides,
  prismic,
  additionalQueries = []
) =>
  prismic.client.get({
    filters: [
      prismic.filter.at('document.type', 'guide'),
      prismic.filter.at('my.guide.parent', id),
      ...additionalQueries,
    ],
    orderings: [
      {
        field: `document.last_publication_date`,
        direction: 'desc',
      },
    ],
    pageSize: numberOfGuides || 6,
    fetchLinks: [...routerLinks, ...marketLinks],
  });

const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const formatResults = (results) => {
  return results.map((result) => ({
    edito_carousel_card: { ...result, link_type: 'Document' },
  }));
};

const getGuides = async (
  guide,
  parent,
  numberOfGuides,
  grandParent,
  prismic,
  i18n
) => {
  mergeI18n(i18n, dataI18n);
  console.log('guide.id', guide.id);
  // 1. Get children
  const childrenGuides = await queryGuides(guide.id, numberOfGuides, prismic);

  if (childrenGuides.results_size > 0) {
    return {
      cards: formatResults(childrenGuides.results),
      title: i18n.t('related-guides.en-savoir-plus-sur', {
        guide: lowercaseFirstLetter(asText(guide.title)),
      }),
    };
  }

  // 2. Else, try getting sibling
  const siblingGuides = parent
    ? await queryGuides(parent.id, numberOfGuides, prismic, [
        prismic.filter.not('my.guide.uid', guide.uid),
      ])
    : null;

  if (siblingGuides?.results_size > 0) {
    return {
      cards: formatResults(siblingGuides.results),
      title: i18n.t('related-guides.en-savoir-plus-sur', {
        guide: lowercaseFirstLetter(asText(parent.title)),
      }),
    };
  }

  // 3. Else, try getting pibling (siblings of the parent)
  const piblingGuides = grandParent
    ? await queryGuides(grandParent.id, numberOfGuides, prismic)
    : null;

  if (piblingGuides?.results_size > 0) {
    return {
      cards: formatResults(piblingGuides.results),
      title: i18n.t('related-guides.en-savoir-plus-sur', {
        guide: lowercaseFirstLetter(asText(grandParent.title)),
      }),
    };
  }
};

export default async (
  { primary },
  pageData,
  { $i18n, $prismic, $enhancedLinkSerializer }
) => {
  const guide = {
    id: pageData.id,
    uid: pageData.uid,
    title: pageData.title,
  };

  const parent = pageData.parent?.id
    ? {
        id: pageData.parent.id,
        uid: pageData.parent.uid,
        title: pageData.parent.data?.title,
      }
    : null;

  let grandParent = null;
  const grandParentId = pageData.parent?.data?.parent?.id;

  if (grandParentId) {
    const grandParentData = await querySingleGuide(grandParentId, $prismic);
    grandParent = {
      id: grandParentData.id,
      uid: grandParentData.uid,
      title: grandParentData.data?.title,
    };
  }

  const guides = await getGuides(
    guide,
    parent,
    primary.number_of_guides,
    grandParent,
    $prismic,
    $i18n
  );

  if (!guides) {
    return;
  }

  return {
    title:
      stripRichTextWrapperTag(primary.related_guides_title) || guides.title,
    withCarousel: false,
    cards: cardsAdapter(guides.cards, $i18n, $enhancedLinkSerializer),
  };
};
